
import Vue from 'vue';
import { mapState } from 'vuex';
import PageTitle from '@/components/common/PageTitle.vue';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesExpertListsPublicRequestedExpertsExpertList,
  V1EntitiesExpertListsPublicRequestedExpertsAudience,
  PostV1CouplesIam,
  PostV1Invitees,
} from '@/services/api/tsxass';
import ExpertGroupUserAddingDialog from '@/components/userAddingDialogs/ExpertGroupUserAddingDialog.vue';
import { UserId } from '@/services/api/common/types';
import ExpertGroup from '@/components/assessmentChecklist/ExpertGroupList/ExpertGroup.vue';
import TTLoader from '@/components/ui/TTLoader.vue';

interface PreparedGroup extends V1EntitiesExpertListsPublicRequestedExpertsExpertList {
  couples: Array<{ expert: V1EntitiesExpertListsPublicRequestedExpertsAudience; id: string }>;
}

export default Vue.extend({
  name: 'AskForAssessment',

  components: {
    PageTitle,
    ExpertGroupUserAddingDialog,
    ExpertGroup,
    TTLoader,
  },

  inject: ['RouteNames'],

  data() {
    return {
      expertsListsLoading: false,
      addingLoading: false,
      addingExternalsLoading: false,
      expertsLists: [] as V1EntitiesExpertListsPublicRequestedExpertsExpertList[],
      isDialogShow: false,
      iam: PostV1CouplesIam.SURVEYEE,
      groupId: 0,
    };
  },

  computed: {
    ...mapState('engagementInfo', {
      user: 'user',
    }),
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    preparedGroupList(): PreparedGroup[] {
      return this.expertsLists.map((group) => {
        const couples = group.experts.map((expert) => ({
          // id для внутреннего использования
          id: `group-${group.id}_expert-${expert.userId}`,
          expert,
        }));
        return {
          ...group,
          couples,
        };
      });
    },
  },

  mounted() {
    this.loadRequestedExperts();
  },

  methods: {
    canAddExternalExperts(groupId: number): boolean {
      const selectedGroup = this.expertsLists?.find((list) => list.id === groupId);
      return Boolean(selectedGroup?.canAddExternalExperts);
    },
    closeDialogAndRedirect() {
      this.isDialogShow = false;
      this.$router.push({ name: this.RouteNames.R_APP_REQUEST_HAS_SENT });
    },
    async addExperts(userIds: UserId[]) {
      if (!userIds.length) {
        return;
      }
      try {
        this.addingLoading = true;
        await tsxassApi.postV1CouplesByBatch({
          expertListId: this.groupId,
          couples: userIds.map((userId) => ({
            expertUserId: userId,
            surveyeeUserId: this.user?.userId,
          })),
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        if (!this.addingExternalsLoading) {
          this.closeDialogAndRedirect();
        }
        this.addingLoading = false;
      }
    },
    async addExternalExperts(externalUsers: PostV1Invitees[]) {
      if (!externalUsers.length) {
        return;
      }
      try {
        this.addingExternalsLoading = true;
        await Promise.allSettled(
          externalUsers.map(async (user) => {
            await tsxassApi.postV1Invitees(user);
          }),
        );
      } catch (err) {
        errorHandler(err);
      } finally {
        if (!this.addingLoading) {
          this.closeDialogAndRedirect();
        }
        this.addingExternalsLoading = false;
      }
    },

    async loadRequestedExperts() {
      try {
        this.expertsListsLoading = true;
        const {
          data: { expertsLists },
        } = await tsxassApi.getV1SurveysSurveyIdRequestedExperts(this.surveyId);
        this.expertsLists = expertsLists;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.expertsListsLoading = false;
      }
    },
    showAddExpertsDialog(expertListId: number) {
      this.groupId = Number(expertListId);
      this.isDialogShow = true;
    },
  },
});
